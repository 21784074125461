import { SettingItem } from "providers/User/UserActionTypes";

export const EMAIL_NOTIFICATIONS = "emailNotifications";

export class UserUtil {
  static emailNotificationsUndefined(settings: SettingItem[]): boolean {
    let unset = false;
    settings.forEach((value) => {
      if (
        value.name === EMAIL_NOTIFICATIONS &&
        value.value === null &&
        value.required
      ) {
        unset = true;
        return;
      }
    });
    return unset;
  }

  static emailNotificationsChecked(settings: SettingItem[]): boolean {
    let checked = false;
    settings.forEach((value) => {
      if (value.name === EMAIL_NOTIFICATIONS && value.value === "1") {
        checked = true;
        return;
      }
    });
    return checked;
  }

  static setEmailNotificationsEnabled(
    enabled: boolean,
    settings: SettingItem[]
  ): SettingItem[] {
    return settings.map((item) => ({
      ...item,
      value:
        item.name === EMAIL_NOTIFICATIONS
          ? enabled ? "1" : "0"
          : item.value ?? "0",
    }));
  }
}
